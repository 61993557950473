import InheritInput from "../../../../../../components/UI/inputs/inheritinput/InheritInput";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";
import {Button, Form, Modal} from "react-bootstrap";
import ListIcon from "../../../../../../components/UI/icons/ListIcon";
import EditIcon from "../../../../../../components/UI/icons/EditIcon";
import CrossIcon from "../../../../../../components/UI/icons/CrossIcon";
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../../hooks/useAxiosHelper";
import useLoader from "../../../../../../hooks/useLoader";
import {useEffect, useState} from "react";
import ProvidersKp from "../providerskp/ProvidersKp";

const KpMaterialsList = ({
                             currentKp,
                             changeKp,
                             getKp,
                             setItemKPForEdit,
                             setVisibleEditModalKPItem,
                             deleteMaterial,
                             setIsFirstLoad
                         }) => {
    const {show, hide} = useLoader();
    const [visibleProvision, setVisibleProvision] = useState(false);
    const [itemForProvision, setItemForProvision] = useState(null);

    const clearProvision = () => {
        setVisibleProvision(false);
        setItemForProvision(null);
    }

    useEffect(() => {
        if (itemForProvision) {
            let material = currentKp?.materials?.find(mat => mat?.id === itemForProvision?.item?.id);
            if (material) setItemForProvision({type: itemForProvision?.type, item: material})
        }
    }, [currentKp]);

    const client = useQueryClient();
    const {mutate: changePositionMaterial} = useMutation({
        mutationKey: ['current-kp', currentKp?.id],
        mutationFn: ({oldItemKey, newItemKey}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/kp/edit/position/material',
            queryParams: {
                oldItemKey: oldItemKey,
                newItemKey: newItemKey
            },
        }),
        onSuccess: () => {
            getKp()
            client.invalidateQueries({queryKey: ['current-kp', currentKp?.id]})
        },
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const schema = [
        {
            title: 'Наименование',
            callbackItem: item => (
                <div className="kp-detail__item_name">
                    {item?.materialType === "equipment" ? item?.equipment?.name : item?.component?.name}
                </div>
            ),
        },
        {
            title: 'Тип',
            callbackItem: item => (
                <div className="kp-detail__item_type">
                    {item?.materialType === "equipment" ? 'Готовая продукция' : 'Компонент'}
                </div>
            ),
        },
        {
            title: 'Количество',
            callbackItem: item => (
                <InheritInput
                    isNumber
                    defaultValue={item?.isCanPartialBuy ? item?.quantity : item?.quantityIncludingPackaging}
                    onChange={newValue => changeKp({...item, quantity: newValue}, true)}
                    className={'kp-detail__item_quantity'}
                    useDebounce
                    controlled
                />
            ),
        },
        {
            title: 'Единица измерения',
            callbackItem: item => (
                <div className="kp-detail__item_unit">
                    {item?.unitMeasurement?.unitName || 'Не определено'}
                </div>
            ),
        },
        {
            title: 'Цена за единицу объема',
            callbackItem: item => (
                <div className="kp-detail__item_price">
                    {getCurrencyString(item?.price || 0, currentKp?.currencyForHandle?.currencyCode)}
                </div>
            ),
        },
        {
            title: 'Скидка',
            callbackItem: item => (
                <InheritInput
                    isNumber
                    defaultValue={item?.discount || '0'}
                    onChange={newValue => changeKp({...item, discount: newValue}, true)}
                    className={'kp-detail__item_discount'}
                    useDebounce
                    controlled
                />
            ),
        },
        {
            title: 'Итого кратно таре',
            callbackItem: item => (
                <div className="kp-detail__item_total">
                    {getCurrencyString(item?.isCanPartialBuy
                            ? item?.totalPrice
                            : item?.totalPriceIncludingPackaging || 0,
                        currentKp?.currencyForHandle?.currencyCode
                    )}
                </div>
            ),
        },
        {
            title: 'Частичная продажа',
            callbackItem: item => (
                <Form.Check
                    className={'kp-detail__item_total'}
                    checked={item?.isCanPartialBuy}
                    onChange={e => changeKp({
                        ...item,
                        isCanPartialBuy: !item?.isCanPartialBuy
                    }, false)}
                />
            ),
        },
    ]
    const popoverActions = [
        {
            icon: <ListIcon classNames={'icon-extension'}/>,
            title: 'Обеспечение',
            onClick: (item) => {
                setVisibleProvision(true);
                setItemForProvision({item, type: item.materialType})
            }
        },
        {
            icon: <EditIcon classNames={'icon-extension'}/>,
            title: 'Редактировать',
            onClick: (item) => setItemKPForEdit(item) || setVisibleEditModalKPItem(true)
        },
        {
            icon: <CrossIcon classNames={'icon-extension'}/>,
            title: 'Удалить',
            onClick: (item) => {
                deleteMaterial({
                    guid: item.guid,
                    kpId: currentKp?.id,
                    material: item
                })
                setIsFirstLoad(1);
            }
        },
    ]
    return (
        <>
            <TableBootstrap
                popoverActions={popoverActions}
                popoverCaption={'Действия'}
                items={currentKp?.materials}
                schema={schema}
                draggable
                keySelector={material => material?.id}
                positionSelector={material => material?.position}
                onChangePosition={position => changePositionMaterial(position)}
            />
            <Modal
                show={visibleProvision}
                onHide={clearProvision}
            >
                <Modal.Header><Modal.Title>Обеспечение</Modal.Title></Modal.Header>
                <Modal.Body>
                    <ProvidersKp
                        item={itemForProvision?.item}
                        type={itemForProvision?.type}
                        getKp={getKp}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={clearProvision}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default KpMaterialsList;