import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../../hooks/useAxiosHelper";
import {Button, Form, Stack} from "react-bootstrap";
import useLoader from "../../../../../../hooks/useLoader";
import ProvidersItemKp from "../providersitemkp/ProvidersItemKp";

const ProvidersKp = ({item, type, getKp}) => {
    const {show, hide} = useLoader();

    const client = useQueryClient();
    const {mutate: addProvisionItemKp} = useMutation({
        mutation: ['current-kp', item?.kpId],
        mutationFn: ({type, id}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/kp/add/provision',
            queryParams: {
                type: type,
                itemId: id,
                kpId: item?.kpId,
            }
        }),
        onSuccess: () => {
            getKp();
            client.invalidateQueries({queryKey: ['current-kp', item.kpId]});
        },
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: removeProvisionItem} = useMutation({
        mutationKey: ['current-kp', item?.kpId],
        mutationFn: ({type, guid, itemId}) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/kp/delete/provision',
            queryParams: {
                type: type,
                itemId: itemId,
                guid: guid,
                kpId: item?.kpId,
            },
        }),
        onSuccess: () => {
            getKp();
            client.invalidateQueries({queryKey: ['current-kp', item?.kpId]});
        },
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const {mutate: changeProvisionItem} = useMutation({
        mutationKey: ['current-kp', item?.kpId],
        mutationFn: (data) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/kp/edit/provision',
            data: data,
            queryParams: {
                kpId: item?.kpId,
            },
        }),
        onSuccess: () => {
            getKp();
            client.invalidateQueries({queryKey: ['current-kp', item?.kpId]})
        }
    })

    return (
        <Form>
            <Form.Group>
                <Form.Label>Материал: <b>{type === 'equipment'
                    ? item?.equipment?.name || 'Нет данных'
                    : item?.component?.name || 'Нет данных'
                }</b></Form.Label>
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Label>Требуется обеспечить:
                    {type === 'equipment' || type === 'component' ? item?.quantityIncludingPackaging : item?.quantityDiluentIncludingPackaging}
                    {item?.unitMeasurement?.unitName || ''}</Form.Label>
            </Form.Group>
            {item?.providers?.map(provider => (
                <ProvidersItemKp
                    key={provider.guid}
                    item={provider}
                    remains={item?.materialType === 'equipment' ? item?.equipment?.remains : item?.component?.remains}
                    removeProvidersItem={guid => removeProvisionItem({type, guid, itemId: item?.id})}
                    changeProvidersItem={newItem => changeProvisionItem(newItem)}
                />
            ))}
            <Stack className={'flex align-items-end justify-content-center'}>
                <Button onClick={() => {
                    addProvisionItemKp({type, id: item?.id})
                }}>Добавить</Button>
            </Stack>
        </Form>
    );
};

export default ProvidersKp;