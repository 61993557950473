import {Image, StyleSheet, Text, View} from "@react-pdf/renderer";
import {BaseUrl} from "../../../../../../features/databaseinfo/BaseUrl";
import TkpHeaderPdfV2 from "./TkpHeaderPdfV2";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";

const TkpInformationPagePdfV2 = ({memoVisiblePrice, tkp, totalAdditionalMaterialsPrice}) => {
    return (
        <View style={[infoStyles.wrapper, {gap: 10, padding: '10 10'}]}>
            <Image
                src={
                    `${BaseUrl}/photo/img/BackgroundAncorr.png`
                }
                style={infoStyles.backgroundImage}
            />
            <TkpHeaderPdfV2 tkp={tkp}/>
            <View style={[{width: '100%'}]}>
                {/*<Text style={[infoStyles.title, infoStyles.titleName]}>Евгений Витальевич</Text>*/}
                <Text style={infoStyles.title}>
                    Компания ООО АНКОРР предлагает Вам ознакомиться
                </Text>
                <Text style={infoStyles.title}>
                    с технико-коммерческим предложением для окраски
                    судна "{tkp?.ship?.toUpperCase() || ''}"
                </Text>
            </View>
            <View style={[infoStyles.priceAndImage, infoStyles.marginBlock, infoStyles.block, tkp?.shipPhoto?.urlPath ? {minHeight: '216px'} : {}]}>
                <View>
                    {memoVisiblePrice ?
                        <Text style={infoStyles.defaultTitle}>
                            Полная стоимость
                            предложения:
                        </Text>
                        :
                        <Text style={infoStyles.defaultTitle}>
                            Характеристики:
                        </Text>
                    }
                    <View style={[{marginTop: '5pt'}]}>
                        {memoVisiblePrice &&
                            <View style={infoStyles.line}>
                                <View style={infoStyles.point}></View>
                                <Text style={infoStyles.defaultText}>
                                    Стоимость без НДС: {getCurrencyString(tkp?.totalPriceIncludingPackaging - tkp?.totalPriceIncludingPackagingVAT, tkp?.currencyForHandle?.currencyCode)}
                                </Text>
                            </View>
                        }
                        {memoVisiblePrice &&
                            <View style={infoStyles.line}>
                                <View style={infoStyles.point}></View>
                                <Text style={infoStyles.defaultText}>
                                    НДС {tkp.vat}%: {getCurrencyString((tkp?.totalPriceIncludingPackagingVAT).toFixed(2), tkp?.currencyForHandle?.currencyCode)}
                                </Text>
                            </View>
                        }
                        {memoVisiblePrice &&
                            <View style={infoStyles.line}>
                                <View style={infoStyles.point}></View>
                                <Text style={infoStyles.defaultText}>
                                    Итого с НДС: {getCurrencyString((tkp?.totalPriceIncludingPackaging).toFixed(2), tkp?.currencyForHandle?.currencyCode)}
                                </Text>
                            </View>
                        }
                        <View style={infoStyles.line}>
                            <View style={infoStyles.point}></View>
                            <Text style={infoStyles.defaultText}>
                                Вес: {tkp?.totalWeight?.toFixed(2)} кг
                            </Text>
                        </View>
                        <View style={infoStyles.line}>
                            <View style={infoStyles.point}></View>
                            <Text style={infoStyles.defaultText}>
                                Объем: {tkp?.totalVolume} м²
                            </Text>
                        </View>
                        <View style={infoStyles.line}>
                            <View style={infoStyles.point}></View>
                            <Text style={infoStyles.defaultText}>
                                Итого литров: {tkp?.totalLiters}
                            </Text>
                        </View>
                        {tkp?.priceNote &&
                            <View style={infoStyles.line}>
                                <View style={infoStyles.point}></View>
                                <Text style={infoStyles.defaultText}>
                                    {tkp?.priceNote}
                                </Text>
                            </View>
                        }
                    </View>
                    {memoVisiblePrice &&
                        <View style={infoStyles.marginBlock}>
                            <Text style={infoStyles.defaultTitle}>
                                В стоимость включено:
                            </Text>
                            <View style={[{marginTop: '5pt'}]}>
                                <View style={infoStyles.line}>
                                    <View style={infoStyles.point}></View>
                                    <Text style={infoStyles.defaultText}>
                                        Материалы
                                        стоимость c НДС: {getCurrencyString(tkp?.priceForMaterialIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}
                                    </Text>
                                </View>
                                <View style={infoStyles.line}>
                                    <View style={infoStyles.point}></View>
                                    <Text style={infoStyles.defaultText}>
                                        Разбавители
                                        стоимость с НДС: {getCurrencyString(tkp?.priceForDiluentIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}
                                    </Text>
                                </View>
                                {totalAdditionalMaterialsPrice > 0 &&
                                    <View style={infoStyles.line}>
                                        <View style={infoStyles.point}></View>
                                        <Text style={infoStyles.defaultText}>
                                            Дополнительный объем ЛКМ к расчетному
                                            стоимость с НДС: {
                                                getCurrencyString(totalAdditionalMaterialsPrice,
                                                    tkp?.currencyForHandle?.currencyCode)
                                            }
                                        </Text>
                                    </View>
                                }
                                {/*{tkp?.settings.deliveryPrice &&*/}
                                {/*    <View style={infoStyles.line}>*/}
                                {/*        <View style={infoStyles.point}></View>*/}
                                {/*        <Text style={infoStyles.defaultText}>*/}
                                {/*            Доставка: {getCurrencyString(tkp?.settings.deliveryPrice, tkp?.currencyForHandle?.currencyCode)}*/}
                                {/*        </Text>*/}
                                {/*    </View>*/}
                                {/*}*/}
                            </View>
                        </View>
                    }
                </View>
                {tkp?.shipPhoto?.urlPath &&
                    <View style={infoStyles.shipImage}>
                        <Image
                            src={tkp?.shipPhoto?.urlPath}
                            style={infoStyles.shipImage}
                        />
                    </View>
                }
            </View>
            <View style={[infoStyles.marginBlock, infoStyles.block, {height: '100%'}]}>
                <Text style={infoStyles.defaultTitle}>
                    Условия предложения:
                </Text>
                <View style={[{marginTop: '5pt'}]}>
                    {memoVisiblePrice &&
                        <View style={infoStyles.line}>
                            <View style={infoStyles.point}></View>
                            <Text style={infoStyles.defaultText}>
                                Условия оплаты: {tkp?.paymentTerms || 'Не определено'}
                            </Text>
                        </View>}
                    <View style={infoStyles.line}>
                        <View style={infoStyles.point}></View>
                        <Text style={infoStyles.defaultText}>
                            Адрес доставки: {tkp?.deliveryAddress || 'Не определено'}
                        </Text>
                    </View>
                    <View style={infoStyles.line}>
                        <View style={infoStyles.point}></View>
                        <Text style={infoStyles.defaultText}>
                            Способ доставки: {tkp?.deliveryMethod || 'Не определено'}
                        </Text>
                    </View>
                    <View style={infoStyles.line}>
                        <View style={infoStyles.point}></View>
                        <Text style={infoStyles.defaultText}>
                            Срок отгрузки: {tkp?.shippingTime || 'Не определено'}
                        </Text>
                    </View>
                    <View style={infoStyles.line}>
                        <View style={infoStyles.point}></View>
                        <Text style={infoStyles.defaultText}>
                            Склад поставщика: {tkp?.supplyWarehouse || 'Не определено'}
                        </Text>
                    </View>
                    {tkp?.termsNote &&
                        <View style={infoStyles.line}>
                            <View style={infoStyles.point}></View>
                            <Text style={infoStyles.defaultText}>
                                {tkp?.termsNote}
                            </Text>
                        </View>
                    }
                </View>
            </View>
        </View>
    );
};
const infoStyles = StyleSheet.create({
    title: {
        lineHeight: '3px',
        fontSize: '11px',
        fontWeight: 'normal',
        textAlign: 'center',
    },
    titleName: {
        fontWeight: 'bold',
    },
    wrapper: {
        fontFamily: 'Montserrat',
        position: "relative",
        zIndex: 30,
        backgroundColor: 'white',
        width: '100%',
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    backgroundImage: {
        zIndex: 15,
        position: "absolute",
        width: '100%',
        height: "100%",
        alignItems: "center"
    },
    priceAndImage: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 16,
        width: '100%'
    },
    shipImage: {
        width: '314px',
        height: '216px',
        borderRadius: '15'
    },
    defaultTitle: {
        color: '#009FE3',
        fontFamily: 'Montserrat',
        fontSize: '11',
        fontWeight: 'bold'
    },
    defaultText: {
        fontFamily: 'Montserrat',
        fontSize: '11',
        fontWeight: 'normal',
        lineHeight: '1.3'
    },
    marginBlock: {
        marginTop: '15pt'
    },
    block: {
        width: '100%',
        padding: '0 10px',
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5pt',
        alignItems: "center"
    },
    point: {
        width: '3pt',
        height: '3pt',
        borderRadius: '100%',
        backgroundColor: '#000'
    }
})

export default TkpInformationPagePdfV2;