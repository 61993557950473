import {forwardRef, useImperativeHandle, useState} from 'react';
import useTkp from "../../../pages/newTkp/hooks/useTkp";
import {Button, Modal} from "react-bootstrap";
import FormEditSystem from "../../../pages/newTkp/components/formeditsystem/FormEditSystem";

const TkpEditSystemModule = forwardRef((props, ref) => {

    const [systemEdit, setSystemEdit] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const {changeSystemTkp} = useTkp()

    useImperativeHandle(ref, () => ({
        openForm: (system) => {
            setSystemEdit(system)
            setVisibleModal(true)
        }
    }), [])

    return (
        <Modal
            show={visibleModal}
            onHide={() => {
                setVisibleModal(false)
                setSystemEdit(null)
            }}
            size={"lg"}
        >
            <Modal.Header><Modal.Title>Редактирование системы</Modal.Title></Modal.Header>
            <Modal.Body>
                <FormEditSystem system={systemEdit} setSystem={setSystemEdit}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"danger"} onClick={() => {
                    setVisibleModal(false)
                    setSystemEdit(null)
                }}>Закрыть</Button>
                <Button
                    onClick={() => {
                        changeSystemTkp(systemEdit, false)
                        setVisibleModal(false)
                        setSystemEdit(null)
                    }}
                >
                    Применить
                </Button>
            </Modal.Footer>
        </Modal>
    )
});

export default TkpEditSystemModule;